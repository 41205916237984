import React from 'react'
import ProductPreview from '../product/Preview'
import { useMaterialsData } from '../../lib/materials_hook'
import { ProductMaterial, PaginationProps , ProductData } from '../../types'
import { isConnectedMaterialBundle } from '../../utils/Mapping'
import { useBundleData } from '../../lib/products/bundle_hooks'
import LinearProgress from '@mui/material/LinearProgress'
import Pagination from '../generic/Pagination'

const BundleOverview = (options: any) => {
  const materialsData = useMaterialsData()

  const i = 0
  const page = parseInt(options.page) || 1

  const bundleId = options.bundleId
  // bundleId = 573

  const bundleData = useBundleData({
    bundleId: bundleId,
    page: page,
    removeDuplicates: options.removeDuplicates,
  })

  if (typeof bundleData === 'undefined') {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <LinearProgress />
            </div>
          </div>
        </div>
      </>
    )
  } else {
    const pageCount = bundleData['pageCount']
    const productsData: ProductData[] = bundleData['result']

    // bundleData['result'].map((pd: ProductData) => {
    //   if (pd.materialgroup == 'cup') {
    //     pd.materialgroup = 'cups'
    //   }
    //   productsData.push(pd)
    // })

    const paginationProps: PaginationProps = {
      baseUrl: options.base_url,
      urlTemplate: '/%d',
      currentPage: page,
      pageCount: pageCount,
    }

    return (
      <>
        <div className="row">
          <div className="col d-flex justify-content-center justify-content-md-end padding-bottom">
            <Pagination pagination={paginationProps} />
          </div>
        </div>
        <div className="row">
          {productsData.map((productData: ProductData, i) => (
            <div className="col-6 col-sm-4" key={'product_preview_' + i}>
              <ProductPreview
                material={'' + productData.materialgroup}
                materials={materialsData.filter(
                  (materialData: ProductMaterial) => {
                    return isConnectedMaterialBundle(
                      materialData,
                      '' + productData.materialgroup,
                    )
                  },
                )}
                productData={productData}
                setOnClick={options.setOnClick}
              />
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center justify-content-md-end padding-bottom">
            <Pagination pagination={paginationProps} />
          </div>
        </div>
      </>
    )
  }
}

export default BundleOverview
